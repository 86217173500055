
import React, {useState} from 'react'
import '../../css/style.css';
import '../../css/common.css';
import Contact from '../../Contact';
import { BrowserView, MobileView } from 'react-device-detect'
import {Button, Col, Row} from 'react-bootstrap'
import Card from "react-bootstrap/Card";
import ReactDOM from "react-dom";
import ReactCardFlipper from "react-card-flipper";
import ReactCardFlip from 'react-card-flip';
import Carousel from 'react-bootstrap/Carousel';


function Mainpart() {

  const [isFlipped1, setIsFlipped1] = useState(false);
  const handleClick1 = () => {
    setIsFlipped1(!isFlipped1);
  };

  const [isFlipped2, setIsFlipped2] = useState(false);
  const handleClick2 = () => {
    setIsFlipped2(!isFlipped2);
  };

  const [isFlipped3, setIsFlipped3] = useState(false);
  const handleClick3 = () => {
    setIsFlipped3(!isFlipped3);
  };

  const [isFlipped4, setIsFlipped4] = useState(false);
  const handleClick4 = () => {
    setIsFlipped4(!isFlipped4);
  };



    return (
        <div className="Mainpart">
            <div className="container" style={{paddingTop: "25px"}}>
            <BrowserView>
            <p className="h35" style={{color: 'black', textDecoration: 'none', borderLeft: '5px solid #479ff8', top: '50%'}}>&nbsp;IIT Hyderabad ACM Student Chapter</p>
            </BrowserView>
            <MobileView>
            <p className="h35" style={{color: 'black', textDecoration: 'none', borderLeft: '5px solid #479ff8', top: '50%'}}>&nbsp;IIT &nbsp;Hyderabad &nbsp;ACM &nbsp;Student &nbsp;Chapter</p>
            </MobileView>
            {/* <br /> */}
            <div className="row">
                {/* <div class="col-md-2">
                    <BrowserView><img src = {require('../../assets/images/logo.jpg')} style={{height: "90%", width:"110%"}}  alt=" " /></BrowserView>
                </div> */}

                <div class="col-md">
                    {/* <br /> */}
                    <p id="aboutus" className="p18" style={{color: 'black', padding: '5px'}}>The IIT Hyderabad ACM student chapter is an official non profit student chapter of the Association of Computing Machinery (ACM). Our aim is to unite computer fraternity at IIT Hyderabad to foster research in various disciplines of Computer Science under the shelter of department of Computer Science.
                        {/* IIT Hyderabad ACM student chapter has been officially... */}
                        <a href="/about">&nbsp;Know more</a>
                        <br />
                        {/* <button href="/about" type="button" class="btn btn-outline-primary">About Us</button> */}
                        {/* <br /> */}
                        <a href="/joinus" target="_blank"><button class="button"><span>Become a Member</span></button></a>&nbsp;
                        {/* <a href="/about"><button class="button-2"><span>Know more</span></button></a> */}
                    </p>
                </div>
            </div>
            {/* <hr style={{backgroundColor: "#C0C0C0", height:"1px"}} /> */}
            <br />





            <a data-aos="fade-right" class="p30" style={{color: 'black', textDecoration: 'none', borderLeft:'3px solid #479ff8', top: '50%'}}>&nbsp;Interest Groups</a>
            <br /><br />
                <Row>
                <Col  sm="12" md="6" lg="3">
                <ReactCardFlip className="boxWithShadow" isFlipped={isFlipped1} flipDirection="horizontal">
                  <div >
                    <a onClick={handleClick1} style={{cursor: "pointer"}}>
                    <Card  bg="white" text="black" style={{boxShadow: "0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)"}}>
                    <Card.Img variant="top" src={require('../../assets/logos/MachineIntelligenceLogo.png')}  />
                    {/* <Button variant='primary' onClick={handleClick1}>Know More</Button> */}
                    </Card>
                    </a>
                  </div>

                  <div>
                    <Card text="black" style={{boxShadow: "0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)", height: '245px'}}>
                        <Card.Body>
                          <Card.Text>Machine Intelligence Group foucuses on research and applications in areas like Computer Vision, Natural Language and Data Mining. <a href="/info/machine-intelligence" style={{cursor: 'pointer'}}>Know more</a></Card.Text>
                        </Card.Body>
                        <Row style={{marginBottom: '5%'}}>
                          <Col>
                            <a onClick={handleClick1} style={{marginLeft: '15%', cursor: 'pointer'}}><i class="fas fa-arrow-left"></i></a>
                          </Col>
                          <Col>
                            {/* <a href="/info/machine-intelligence" style={{cursor: 'pointer', marginLeft: '70%'}}><i class="fas fa-link"></i></a> */}
                          </Col>
                        </Row>
                    </Card>

                </div>
              </ReactCardFlip>
                <br />
              </Col>

              <Col  sm="12" md="6" lg="3">
                <ReactCardFlip className="boxWithShadow" isFlipped={isFlipped2} flipDirection="horizontal">
                  <div >
                    <a onClick={handleClick2} style={{cursor: "pointer"}}>
                    <Card  bg="white" text="black" style={{boxShadow: "0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)"}}>
                    <Card.Img variant="top" src={require('../../assets/logos/SystemsandNetworksLogo.png')}  />
                    {/* <Button variant='primary' onClick={handleClick1}>Know More</Button> */}
                    </Card>
                    </a>
                  </div>

                  <div>
                    <Card text="black" style={{boxShadow: "0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)", height: '245px'}}>
                        <Card.Body>
                          <Card.Text>Systems and Networks group focuses on research and applications in areas like OS, DBMS and Computer Networks. <a href="/info/systems-and-network" style={{cursor: 'pointer'}}>Know more</a> </Card.Text>
                        </Card.Body>
                        <Row style={{marginBottom: '5%'}}>
                          <Col>
                            <a onClick={handleClick2} style={{marginLeft: '15%', cursor: 'pointer'}}><i class="fas fa-arrow-left"></i></a>
                          </Col>
                          <Col>
                            {/* <a href="/info/systems-and-network" style={{cursor: 'pointer', marginLeft: '70%'}}><i class="fas fa-link"></i></a> */}
                          </Col>
                        </Row>
                    </Card>

                </div>
              </ReactCardFlip>
                <br />
              </Col>

              <Col  sm="12" md="6" lg="3">
                <ReactCardFlip className="boxWithShadow" isFlipped={isFlipped3} flipDirection="horizontal">
                  <div >
                    <a onClick={handleClick3} style={{cursor: "pointer"}}>
                    <Card  bg="white" text="black" style={{boxShadow: "0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)"}}>
                    <Card.Img variant="top" src={require('../../assets/logos/TheoreticalCSLogo.png')}  />
                    {/* <Button variant='primary' onClick={handleClick1}>Know More</Button> */}
                    </Card>
                    </a>
                  </div>

                  <div>
                    <Card text="black" style={{boxShadow: "0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)", height: '245px'}}>
                        <Card.Body>
                          <Card.Text>Theoretical CS focuses on research and applications in areas like DSA, Computationl Complexity Theory and Cryptography.<br/><a href="/info/theoretical-cs" style={{cursor: 'pointer'}}>Know more</a></Card.Text>
                        </Card.Body>
                        <Row style={{marginBottom: '5%'}}>
                          <Col>
                            <a onClick={handleClick3} style={{marginLeft: '15%', cursor: 'pointer'}}><i class="fas fa-arrow-left"></i></a>
                          </Col>
                          <Col>
                            {/* <a href="/info/theoretical-cs" style={{cursor: 'pointer', marginLeft: '70%'}}><i class="fas fa-link"></i></a> */}
                          </Col>
                        </Row>
                    </Card>

                </div>
              </ReactCardFlip>
                <br />
              </Col>

              <Col  sm="12" md="6" lg="3">
                <ReactCardFlip className="boxWithShadow" isFlipped={isFlipped4} flipDirection="horizontal">
                  <div >
                    <a onClick={handleClick4} style={{cursor: "pointer"}}>
                    <Card  bg="white" text="black" style={{boxShadow: "0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)"}}>
                    <Card.Img variant="top" src={require('../../assets/logos/WomenInCSLogo.png')}  />
                    {/* <Button variant='primary' onClick={handleClick1}>Know More</Button> */}
                    </Card>
                    </a>
                  </div>

                  <div>
                    <Card text="black" style={{boxShadow: "0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)", height: '245px'}}>
                        <Card.Body>
                          <Card.Text>Women in Computer Science group works towards promoting and supporting the growing community of women in Technology.<br/><a href="/info/women-in-cs" style={{cursor: 'pointer'}}>Know more</a></Card.Text>
                        </Card.Body>
                        <Row style={{marginBottom: '5%'}}>
                          <Col>
                            <a onClick={handleClick4} style={{marginLeft: '15%', cursor: 'pointer'}}><i class="fas fa-arrow-left"></i></a>
                          </Col>
                          <Col>
                            {/* <a href="/info/women-in-cs" style={{cursor: 'pointer', marginLeft: '70%'}}><i class="fas fa-link"></i></a> */}
                          </Col>
                        </Row>
                    </Card>

                </div>
              </ReactCardFlip>
                <br />
              </Col>


        </Row>

            <br /><br />

            <a class="p30" style={{color: 'black', textDecoration: 'none', borderLeft:'3px solid #479ff8', top: '50%'}}>&nbsp;Recents</a>
            <br/><br/>

            <center>
            <BrowserView>
            <Carousel >
              <Carousel.Item style={{alignItems: 'center'}}>
              <Row>
                <Col>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/CS1O1EP5">
                  <img  src={require('../../assets/events/CS1O1_EP5.png')}
                  alt="First slide" style={{width:'90%', height: '200px'}}/>
                  <br/><br/>
                  <h5 style={{color: "black"}}>CS101 EP5: A practical Introduction to CyberSecurity</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Col>
                <Col>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/ResearchConnect/3">
                  <img src={require('../../assets/events/ResearchConnect_3.jpg')}
                  alt="Third slide" style={{width:'90%', height: '200px'}} />
                  <br/><br/>
                  <h5 style={{color: "black"}}>#ResearchConnect: 3D Adversarial Attacks on Deep Learning Models</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Col>
                <Col>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/HourOfCode/2020">
                  <img style={{width:'90%', height: '200px'}} src={require('../../assets/events/HourOfCode.png')}
                  alt="Third slide"/>
                  <br/><br/>
                  <h5 style={{color: "black"}}>HourOfCode 2020</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Col>
                </Row>
              </Carousel.Item>

              <Carousel.Item style={{alignItems: 'center'}}>
              <Row>
                <Col>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/Reading_Group/Systems_and_Networks/1">
                  <img  src={require('../../assets/events/RG_SN_1.png')}
                  alt="First slide" style={{width:'90%', height: '200px'}}/>
                  <br/><br/>
                  <h5 style={{color: "black"}}>Systems and Networks: IR2Vec</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Col>
                <Col>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/ResearchConnect/2">
                  <img src={require('../../assets/events/ResearchConnect_2.png')}
                  alt="Third slide" style={{width:'90%', height: '200px'}} />
                  <br/><br/>
                  <h5 style={{color: "black"}}>#ResearchConnect: Video understanding Using Multimodal Deep Learning</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Col>
                <Col>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/CS1O1EP4">
                  <img style={{width:'90%', height: '200px'}} src={require('../../assets/events/CS1O1_EP4.jpeg')}
                  alt="Third slide"/>
                  <br/><br/>
                  <h5 style={{color: "black"}}>CS101 EP4: Algebra and Cryptography</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Col>
                </Row>
              </Carousel.Item>

            </Carousel>
            </BrowserView>


            <MobileView>
            <Carousel >

                <Carousel.Item style={{alignItems: 'center'}}>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/CS1O1EP5">
                  <img  src={require('../../assets/events/CS1O1_EP5.png')}
                  alt="First slide" style={{width:'90%', height: '200px'}}/>
                  <br/><br/>
                  <h5 style={{color: "black"}}>CS101 EP5: A practical Introduction to CyberSecurity</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Carousel.Item>

                <Carousel.Item style={{alignItems: 'center'}}>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/ResearchConnect/3">
                  <img src={require('../../assets/events/ResearchConnect_3.jpg')}
                  alt="Third slide" style={{width:'90%', height: '200px'}} />
                  <br/><br/>
                  <h5 style={{color: "black"}}>#ResearchConnect: 3D Adversarial Attacks on Deep Learning Models</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Carousel.Item>

                <Carousel.Item style={{alignItems: 'center'}}>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/HourOfCode/2020">
                  <img style={{width:'90%', height: '200px'}} src={require('../../assets/events/HourOfCode.png')}
                  alt="Third slide"/>
                  <br/><br/>
                  <h5 style={{color: "black"}}>HourOfCode 2020</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Carousel.Item>


                <Carousel.Item style={{alignItems: 'center'}}>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/Reading_Group/Systems_and_Networks/1">
                  <img  src={require('../../assets/events/RG_SN_1.png')}
                  alt="First slide" style={{width:'90%', height: '200px'}}/>
                  <br/><br/>
                  <h5 style={{color: "black"}}>Systems and Networks: IR2Vec</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Carousel.Item>
                <Carousel.Item style={{alignItems: 'center'}}>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/ResearchConnect/2">
                  <img src={require('../../assets/events/ResearchConnect_2.png')}
                  alt="Third slide" style={{width:'90%', height: '200px'}} />
                  <br/><br/>
                  <h5 style={{color: "black"}}>#ResearchConnect: Video understanding Using Multimodal Deep Learning</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Carousel.Item>
                <Carousel.Item style={{alignItems: 'center'}}>
                {/* <div className="primaryContainer"> */}
                  <a href="/events/CS1O1EP4">
                  <img style={{width:'90%', height: '200px'}} src={require('../../assets/events/CS1O1_EP4.jpeg')}
                  alt="Third slide"/>
                  <br/><br/>
                  <h5 style={{color: "black"}}>CS101 EP4: Algebra and Cryptography</h5>
                  <br/>
                  </a>
                {/* </div> */}
                </Carousel.Item>


            </Carousel>
            </MobileView>



            </center>
            <br/><br/>

















        </div>



        <div>

  </div>

        <Contact />

       {/* <br /><br /><br /><br /> */}
        </div>








    )
}

export default Mainpart
