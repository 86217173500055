import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, BrowserRouter } from 'react-router-dom';
import App from './App';
import Events from './Events'
import News from './News'
import Blogs from './Blogs'
import Blog1 from './Blogs/Blog1'
import Blog2 from './Blogs/Blog2'
import Blog3 from './Blogs/Blog3'
import Blog4 from './Blogs/Blog4'
import Blog5 from './Blogs/Blog5'
import About from './About'
import MachineIntelligence from './Groups/MachineIntelligence'
import Systemsandnetwork from './Groups/Systemsandnetwork'
import TheoreticalCS from './Groups/TheoreticalCS'
import WomenInCS from './Groups/WomenInCS'
import Projects from './Resources/Projects'
import Posts from './Resources/Posts'
import Publications from './Resources/Publications'
import Joinus from './Joinus'
import CS1O1EP3 from './EventPages/CS1O1EP3'
import CS1O1EP4 from './EventPages/CS1O1EP4'
import CS1O1EP5 from './EventPages/CS1O1EP5'
import ResearchConnect1 from './EventPages/ResearchConnect1'
import ResearchConnect2 from './EventPages/ResearchConnect2'
import ResearchConnect3 from './EventPages/ResearchConnect3'
import RG_TCS_1 from './EventPages/RG_TCS_1'
import RG_SN_1 from './EventPages/RG_SN_1'
import RG_WCS_0 from './EventPages/RG_WCS_0'
import RG_0 from './EventPages/RG_0'
import CS1O1EP2 from './EventPages/CS1O1EP2'
import HourOfCode2020 from './EventPages/HourOfCode2020'
import Inauguration from './EventPages/Inauguration'
import Thankyou from './Thankyou';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Switch>
      <Route path='/' exact render={props => <Joinus {...props} />}/>
      {/* <Route path='/events' exact render={props => <Events {...props} />}/>
      <Route path='/news' exact render={props => <News {...props} />}/>
      <Route path='/blogs' exact render={props => <Blogs {...props} />}/>
      <Route path='/blogs/1' exact render={props => <Blog1 {...props} />}/>
      <Route path='/blogs/2' exact render={props => <Blog2 {...props} />}/>
      <Route path='/blogs/3' exact render={props => <Blog3 {...props} />}/>
      <Route path='/blogs/4' exact render={props => <Blog4 {...props} />}/>
      <Route path='/blogs/5' exact render={props => <Blog5 {...props} />}/>
      <Route path='/about' exact render={props => <About {...props} />}/>
      <Route path='/info/machine-intelligence' exact render={props => <MachineIntelligence {...props} />}/>
      <Route path='/info/systems-and-network' exact render={props => <Systemsandnetwork {...props} />}/>
      <Route path='/info/theoretical-cs' exact render={props => <TheoreticalCS {...props} />}/>
      <Route path='/info/women-in-cs' exact render={props => <WomenInCS {...props} />}/>
      <Route path='/Projects' exact render={props => <Projects {...props} />}/>
      <Route path='/thankyou' exact render={props => <Thankyou {...props} />}/>
      <Route path='/Posts' exact render={props => <Posts {...props} />}/>
      <Route path='/Publications' exact render={props => <Publications {...props} />}/>
      <Route path='/joinus' exact render={props => <Joinus {...props} />}/>
      <Route path='/events/HourOfCode/2020' exact render={props => <HourOfCode2020 {...props} />}/>
      <Route path='/events/CS1O1EP3' exact render={props => <CS1O1EP3 {...props} />}/>
      <Route path='/events/CS1O1EP4' exact render={props => <CS1O1EP4 {...props} />}/>
      <Route path='/events/CS1O1EP5' exact render={props => <CS1O1EP5 {...props} />}/>
      <Route path='/events/ResearchConnect/1' exact render={props => <ResearchConnect1 {...props} />}/>
      <Route path='/events/ResearchConnect/2' exact render={props => <ResearchConnect2 {...props} />}/>
      <Route path='/events/ResearchConnect/3' exact render={props => <ResearchConnect3 {...props} />}/>
      <Route path='/events/Reading_Group/Theoretical_CS/1' exact render={props => <RG_TCS_1 {...props} />}/>
      <Route path='/events/Reading_Group/Systems_and_Networks/1' exact render={props => <RG_SN_1 {...props} />}/>
      <Route path='/events/Reading_Group/Women_in_CS/0' exact render={props => <RG_WCS_0 {...props} />}/>
      <Route path='/events/Reading_Group/0' exact render={props => <RG_0 {...props} />}/>
      <Route path='/events/CS1O1EP2' exact render={props => <CS1O1EP2 {...props} />}/>
      <Route path='/events/Inauguration' exact render={props => <Inauguration {...props} />}/> */}
      <App />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
