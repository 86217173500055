import React from 'react'

function Thankyou() {

    return (
        <div className="Thankyou" style={{backgroundColor: "#F0F8FF", height: "100vh"}}>
            <div className="container" style={{paddingTop: "10%"}}>
                <center><p className="h40">Thank you for your response! Our Team will get back to you shortly.</p></center>
            </div>
        </div>
    )
}

export default Thankyou