import React from 'react'
import '../../css/style.css';
import '../../css/common.css';
import { BrowserView, MobileView } from 'react-device-detect'
import AnchorLink from 'react-anchor-link-smooth-scroll'

function Navbar()   {

    return (
        <div className="Navbar">
        <BrowserView>

        <nav className="navbar navbar-expand-lg fixed-top ">
            <div className="container">
            <div class="logo"><a href="/"><img src = {require('../../assets/images/logo-3.jpg')} style={{height: "7%", width:"7%", marginLeft: "-6%"}}  alt=" " /></a></div>
            <div style={{marginLeft:"-65%"}}>
            <label for="btn" class="icon">
                    <span class="fa fa-bars"></span>
                </label>
                <input type="checkbox" id="btn" />
                <ul>
            <li><a href="" style={{textDecoration: 'none'}}>Home</a></li>
            <li><a href="/about" style={{textDecoration: 'none'}}>About Us</a></li>
            <li><a href="/events" style={{textDecoration: 'none'}}>Events</a></li>
            <li><a href="/blogs" style={{textDecoration: 'none'}}>Blogs</a></li>

            <li>
                    <label for="btn-2" class="show">Interest Groups +</label>
                    <a>Interest Groups</a>
                    <input type="checkbox" id="btn-2" />
                    <ul>
                        <li><a href="/info/machine-intelligence" style={{textDecoration: 'none'}}>Machine Intelligence</a></li>
                        <li><a href="/info/systems-and-network" style={{textDecoration: 'none'}}>Systems and Networks</a></li>
                        <li><a href="/info/theoretical-cs" style={{textDecoration: 'none'}}>Theoretical CS</a></li>
                        <li><a href="/info/women-in-cs" style={{textDecoration: 'none'}}>Women in CS</a></li>
                    </ul>
            </li>

            {/* <li>
                    <label for="btn-3" class="show">Resources +</label>
                    <a>Resources</a>
                    <input type="checkbox" id="btn-3" />
                    <ul>
                        <li><a href="/Posts">Posts</a></li>
                        <li><a href="/Projects">Projects</a></li>
                        <li><a href="/Publications">Publications</a></li>
                    </ul>
            </li> */}

            <li><AnchorLink href="#contactus" style={{textDecoration: 'none'}}>Contact Us</AnchorLink></li>
            <li><a href="/joinus" target="_blank" style={{textDecoration: 'none'}}>Join Us</a></li>
            </ul>
            </div>
        </div>
        </nav>

                 <br /><br /><br /><br />

        </BrowserView>

        <MobileView>

        <nav>
        <div class="logo"><a href=""><img src = {require('../../assets/images/logo-3.jpg')} style={{height: "20%", width:"20%"}} alt=" " /></a></div>
        <label for="btn" class="icon">
                <span class="fa fa-bars"></span>
            </label>
            <input type="checkbox" id="btn" />
            <ul>
            <li><a href="">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/events">Events</a></li>
            <li><a href="/blogs" style={{textDecoration: 'none'}}>Blogs</a></li>
            <li>
                    <label for="btn-2" class="show">Interest Groups +</label>
                    <a href="#">Interest Groups</a>
                    <input type="checkbox" id="btn-2" />
                    <ul>
                        <li><a href="/info/machine-intelligence">Machine Intelligence</a></li>
                        <li><a href="/info/systems-and-network">Systems and Networks</a></li>
                        <li><a href="/info/theoretical-cs">Theoretical CS</a></li>
                        <li><a href="/info/women-in-cs">Women in CS</a></li>
                    </ul>
            </li>

            {/* <li>
                    <label for="btn-3" class="show">Resources +</label>
                    <a href="#">Resources</a>
                    <input type="checkbox" id="btn-3" />
                    <ul>
                        <li><a href="/Posts">Posts</a></li>
                        <li><a href="/Projects">Projects</a></li>
                        <li><a href="/Publications">Publications</a></li>
                    </ul>
            </li> */}

            <li><a href='/acm-iith/src/Components/Home/Mainpart.jsx#contactus'>Contact Us</a></li>
            <li><a href="/joinus" target="_blank">Join Us</a></li>
        </ul>
    </nav>



            {/* <nav style={{ background: 'linear-gradient(#fcfcfc, #fff)' , height: '8vh' }}>
                <div className="hamburger">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                <ul class="nav-links">
                    <li><a href="#">gbhfgrf</a></li>
                    <li><a href="#">gbhfgrf</a></li>
                    <li><a href="#">gbhfgrf</a></li>
                </ul>
            </nav> */}



            {/* <nav className="navbar navbar-expand fixed-top">
                <div className="container">
                    <a className="navbar-brand" href="">
                        <span>
                        <img src = {require('../../assets/images/logo.jpg')} style={{height: "15%", width:"15%"}}  alt=" " />
                        </span>
                    </a>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarText" style={{marginLeft: "auto"}}>
                        <NavDropdown title="Menu" id="basic-nav-dropdown">
                        <Nav.Link href="">Home</Nav.Link>
                        <Nav.Link href="/about">About Us</Nav.Link>
                        <Nav.Link href="/events">Events</Nav.Link>

                        <NavDropdown title="Interest Groups" style={{marginLeft: '10px'}} drop="left" id="basic-nav-dropdown">
                            <NavDropdown.Item style={{fontSize: '13px'}} href="/info/machine-intelligence">Machine Intelligence</NavDropdown.Item>
                            <NavDropdown.Item style={{fontSize: '13px'}} href="/info/systems-and-network">Systems and Network</NavDropdown.Item>
                            <NavDropdown.Item style={{fontSize: '13px'}} href="/info/theoretical-cs">Theoretical CS</NavDropdown.Item>
                            <NavDropdown.Item style={{fontSize: '13px'}} href="/info/women-in-cs">Women in CS</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Resources" style={{marginLeft: '10px'}} drop="left" id="basic-nav-dropdown">
                            <NavDropdown.Item style={{fontSize: '13px'}} href="/Posts">Posts</NavDropdown.Item>
                            <NavDropdown.Item style={{fontSize: '13px'}} href="/Projects">Projects</NavDropdown.Item>
                            <NavDropdown.Item style={{fontSize: '13px'}} href="/Publications">Publications</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link href="/acm-iith/src/Components/Home/Mainpart.jsx#contactus" >Contact Us </Nav.Link>
                        <a href="/joinus"><button class="button"><span>Become a Member</span></button></a>
                        </NavDropdown>
                    </div>
                    </div>
                </nav>
     */}


        </MobileView>
        </div>
    )

}

export default Navbar
